import Icon from 'components/utilities/icons/Icon'
import LoadingScreen from 'components/utilities/loading-indicator/LoadingScreen'
import { TutorialContext } from 'components/utilities/tutorial/TutorialProvider'
import { ContextMenuData } from 'components/wrappers/context-menu-wrapper/ContextMenuWrapper'
import { Data } from 'components/wrappers/course-map-data-wrapper/CourseMapDataWrapper'
import { Interface } from 'components/wrappers/interface-data-wrapper/InterfaceDataWrapper'
import { CurrentStudent } from 'components/wrappers/student-data-wrapper/StudentDataWrapper'
import { UserData } from 'components/wrappers/user-data-wrapper/UserDataWrapper'
import { formatDistanceToNow } from 'date-fns'
import React, {
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react'
import { createPortal } from 'react-dom'
import { arrayRemove, fs } from 'utils/firebase'
import { RouterState } from 'utils/Router'
import c from './plans.module.scss'
import Preview from './preview/Preview'
import { isEqual } from 'lodash'
import { unitAos } from 'utils/services'
import { compileMap, copyWithUpdate, createTransferMap } from 'utils/compile-map/compile-map'
import { analytics } from 'utils/firebase'
import { getFacultyFromCourseCode } from 'utils/data/faculty'
import { isPass } from 'utils/data/grades'
import { BrowserData } from 'components/browser/Browser'
import AosTag from 'components/planner/student-details/area-of-study/AosTag/AosTag'
import ChangeSummaryMini, { compare } from 'components/planner/change-summary/ChangeSummaryMini'
import CourseSearch from './course-search/CourseSearch'
import { defaultYear } from 'utils/data/years'
import LoadingIndicator from 'components/utilities/loading-indicator/LoadingIndicator'

const Plans = () => {
  const { selectedPlan, setSelectedPlan, courseMap, setCourseMap } =
    useContext(Data)
  const { currentStudent, studentPromise, loadingStudentData } = useContext(CurrentStudent)
  const { showPlanningPanel, setShowPlanningPanel } = useContext(Interface)
  const { user } = useContext(UserData)
  const { setBrowserHistory, setPopupContent } = useContext(BrowserData)
  const [plans, setPlans] = useState([])
  const [plansWithData, setPlansWithData] = useState([])
  const [loading, setLoading] = useState(true)
  const [mounted, setMounted] = useState(true)
  const [blockRendering, setBlockRendering] = useState(false)
  const [loadingMessage, setLoadingMessage] = useState('Loading plans')

  useEffect(() => {
    return () => setMounted(false)
  }, [])

  // get list of plans after loading student

  useEffect(() => {
    if (
      currentStudent?.courseEnrolments?.[0]?.id &&
      loading &&
      !blockRendering
    ) {
      fs.collection('students')
        .doc(currentStudent.identifiers?.callistaPersonID)
        .onSnapshot((doc) => {
          if (mounted) {
            setPlans((doc.exists ? doc.data() : { plans: [] }).plans || [])
            setLoading(false)
          }
        })
    }
  }, [currentStudent, loading, blockRendering, courseMap, mounted])

  // get plans from firestore

  useEffect(() => {
    if (plans) {
      plans.forEach((id) =>
        fs
          .collection('student-plans')
          .doc(id)
          .onSnapshot((doc) => {
            const data = doc.data()
            if (data && mounted) {
              setPlansWithData((f) => {
                const list = f.some((item, i) => {
                  if (item.id === data.id) {
                    if (!isEqual(item, data)) f.splice(i, 1, data)
                    return true
                  } else {
                    return false
                  }
                })
                  ? f
                  : [...f, data]

                return list.sort(
                  (a, b) =>
                    (b.logs.edited || b.logs.created) -
                    (a.logs.edited || a.logs.created)
                )
              })
            }
          })
      )
    }
  }, [plans, courseMap, mounted])

  // compile aos name

  const aosName = (aos) => {
    return `${
      aos.category?.slice(0, 2).toUpperCase() ||
      aos.aosType?.slice(0, 2).toUpperCase()
    } \u2013 ${aos.unitSetCode || aos.aos} ${aos.title || aos.aosName}`
  }

  // create new plan

  const [selectEnrolment, setSelectEnrolment] = useState(false)

  const enrolledCourses = currentStudent?.courseEnrolments?.filter(x => 
    x.status === 'ENROLLED'
  )

  const checkEnrolments = (show) => {
    if (show === 'enrolled' && enrolledCourses.length === 1) {
      waitAndCreateNewPlan(currentStudent?.courseEnrolments?.findIndex(x => 
        x.status === 'ENROLLED'
      ))
    } else {
      setSelectEnrolment(enrolledCourses.length === 0 ? 'all' : show)
    }
  }

  const waitAndCreateNewPlan = (index) => {
    if (studentPromise) {
      setLoading(true)
      setBlockRendering(true)
      setLoadingMessage('Loading student enrolment record')
      if (selectEnrolment) setSelectEnrolment(false)
      studentPromise.then((data) => {
        createNewPlan(index, null, data)
        setLoading(false)
      })
    } else {
      createNewPlan(index)
    }
  }

  const createNewPlan = (index, plan, data, transferData = null) => {
    if (selectEnrolment) setSelectEnrolment(false)
    const ref = fs.collection('student-plans').doc()
    let newPlan = !!transferData ?
      createTransferMap(ref.id, transferData, currentStudent, user.displayName)
    :
      compileMap(
        ref.id,
        data ? data : currentStudent,
        index,
        user.displayName
      )

    if (plan) {
      if (newPlan.name === plan.name) {
        newPlan.planName = newPlan.planName + ' copy'
      }
      newPlan.id = ref.id
      newPlan.logs.created = { time: Date.now(), user: user.displayName }
      delete newPlan.logs.edited

      newPlan = copyWithUpdate(newPlan, plan)

      const comparison = compare(newPlan, plan)
      
      if (
        (comparison.aos.additions.length
        + comparison.aos.subtractions.length
        + comparison.units.additions.length
        + comparison.units.subtractions.length)
        > 0
      ) {
        const summary = <ChangeSummaryMini comparison={comparison}/>
        setPopupContent({
          content: summary, 
          heading: 'Student record has been updated',
          actionMessage: 'Confirm'
        })
      }
    }

    // save plan to firestore
    const savePlan = () => {
      ref.set(newPlan).catch((error) => console.log(error))

      setCourseMap(newPlan)

      setPlans((f) => {
        const newList = [...f, ref.id]
        fs.collection('students')
          .doc(currentStudent.identifiers?.callistaPersonID)
          .update({ plans: newList })
          .catch((error) => console.log(error))
        return newList
      })

      // if (!plan) selectPlan(newPlan)
      selectPlan(newPlan)
      
      // log event to analytics
      const userProperties = {
        id: user.id,
        name: user.displayName,
        type: 'Staff',
        faculty: getFacultyFromCourseCode(newPlan.code),
      }
      analytics.setUserProperties(userProperties)
      analytics.logEvent('planCreated', {
        studentId: currentStudent.identifiers.callistaPersonID,
        studentName:
          currentStudent.personName.fullName,
        planId: newPlan.id,
        staffId: user.id,
        staffName: user.displayName,
        courseCode: newPlan.code,
        courseTitle: newPlan.title,
        faculty: getFacultyFromCourseCode(newPlan.code),
      })
    }

    // assign aos labels
    if (newPlan.aos?.length) {
      unitAos({ aos: newPlan.aos.map((item) => item.code) }).then((res) => {
        res.forEach((obj) => {
          newPlan.advancedStandings.forEach((block) => {
            if (block.name === obj.unit)
              block.aos
                ? block.aos.push({ code: obj.aos, name: aosName(obj) })
                : (block.aos = [{ code: obj.aos, name: aosName(obj) }])
          })

          Object.values(newPlan.years).forEach((year) =>
            year.periods.forEach((period) =>
              period.blocks.forEach((block, i) => {
                //if not fail/discon
                if (
                  block.name === obj.unit &&
                  (block.grade
                    ? isPass(block.grade)
                    : !['DISCONTIN'].includes(block.status))
                ) {
                  block.aos
                    ? block.aos.push({ code: obj.aos, name: aosName(obj) })
                    : (block.aos = [{ code: obj.aos, name: aosName(obj) }])
                }
              })
            )
          )
        })

        savePlan()
      })
    } else {
      savePlan()
    }
  }

  // select plan

  const { redirect } = useContext(RouterState)

  const selectPlan = (item) => {
    setBrowserHistory({})
    setSelectedPlan(() => {
      sessionStorage.setItem('selectedPlan', JSON.stringify(item.id))
      redirect(
        '/browse',
        item.code
          ? {
              type: 'course',
              code: item.code,
              year: item.startingYear,
            }
          : null
      )
      return item
    })
  }

  // delete plan
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)
  const [planIdToDelete, setPlanIdToDelete] = useState(null)
  const [studentId, setStudentId] = useState(null)
  const [planIndex, setPlanIndex] = useState(null)

  const deletePlan = (id, student, index) => {
    setShowDeleteConfirmation(true)
    setPlanIdToDelete(id)
    setStudentId(student)
    setPlanIndex(index)
  }

  const confirmDelete = () => {
    setShowDeleteConfirmation(false)

    if (planIdToDelete === selectedPlan?.id || !plans.length) {
      setShowPlanningPanel(false)
      setSelectedPlan(null)
      sessionStorage.setItem('selectedPlan', JSON.stringify(null))
    }

    setPlansWithData((f) => {
      const newList = [...f]
      newList.splice(planIndex, 1)
      return newList
    })

    fs.collection('student-plans')
      .doc(planIdToDelete)
      .delete()
      .then(() => console.log(`Deleted ${planIdToDelete}`))
      .catch((error) => console.log(error))

    fs.collection('students')
      .doc(studentId)
      .update({ plans: arrayRemove(planIdToDelete) })
      .then(() => console.log(`Removed ${planIdToDelete} from ${studentId}`))
      .catch((error) => console.log(error))
  }

  // context menu
  const { setMenu } = useContext(ContextMenuData)

  const contextMenu = (e, item, i) => {
    e.preventDefault()
    setMenu({
      id: item.id,
      position: { x: e.clientX, y: e.clientY },
      items: [
        (!item.transfer && {
          name: 'Make copy',
          icon: <Icon.Copy />,
          fn: () => {
            let index = currentStudent.courseEnrolments
              .filter((item) => item.location 
                && !['UNCONFIRM'].includes(item.status)
              )
              .findIndex(c => c.courseCode === item.code)
            createNewPlan(index >= 0 ? index : 0, item)
          },
        }),
        {
          name: 'Delete plan',
          icon: <Icon.Trash />,
          className: c.red,
          fn: () => deletePlan(item.id, item.student, i),
        },
      ],
    })
  }

  const formatDateTime = (time) => {
    if (Date.now() - 172800000 < time) {
      return formatDistanceToNow(time, { addSuffix: true })
    } else {
      let d = new Date(time)
      return `${d.getDate()}/${d.getMonth() + 1}/${d.getFullYear()}`
    }
  }

  // render plans

  const renderPlans = plansWithData
  .sort((a,b) => 
    (b.logs?.created?.time || b.logs?.created || 0) 
    - (a.logs?.created?.time || a.logs?.created || 0)
  )
  .map((item, i) => {
    return (
      <div
        style={{ animationDelay: `${i * 0.2}s` }}
        className={c.plan}
        key={i}
        onClick={() => selectPlan(item)}
        onContextMenu={(e) => contextMenu(e, item, i)}>
        <div className={c.preview}>
          <Preview data={item} />
        </div>
        <div className={c.body}>
          <div className={c.content}>
            <div
              className={c.header}
              style={{ flexDirection: showPlanningPanel ? 'column' : 'row' }}>
              <h2>{item.planName}</h2>
              {item.logs.viewed && (
                <span className={c.viewed}>
                  <Icon.Eye /> Viewed {formatDateTime(item.logs.viewed)}{' '}
                </span>
              )}
            </div>
            <span>
              {item.code} {'\u2013'} {item.title}
            </span>
            <ul className={c.aos}>
              {item.aos
                ? item.aos.map((aos, j) => {
                    return (
                      <AosTag key={j} aos={aos}>
                        {aos.name || aos}
                      </AosTag>
                    )
                  })
                : 'None selected'}
            </ul>
          </div>
          <div className={c.meta}>
            <span>
              <label>Created:</label>{' '}
              {formatDateTime(item.logs.created.time || item.logs.created)}{' '}
              {item.logs.created.user && `by ${item.logs.created.user}`}
            </span>

            {item.logs?.edited && item.id !== selectedPlan?.id && (
              <span>
                <label>Last edit:</label>{' '}
                {formatDateTime(item.logs.edited.time)} by{' '}
                {item.logs.edited.user}
              </span>
            )}
            {item.id === selectedPlan?.id && (
              <span className={c.current}>Currently selected</span>
            )}
          </div>
        </div>
      </div>
    )
  })

  //tutorial
  const { updateRef } = useContext(TutorialContext)
  const containerRef = useRef()

  useLayoutEffect(() => {
    updateRef('Information panel', containerRef.current)
  }, [updateRef])

  //enrolment selection & course transfer
  const [transferData, setTransferData] = useState({
    courses: null,
    from: null,
    year: null,
    sem: null,
    campus: null,
    mode: null
  })

  const currentCourses = currentStudent.courseEnrolments
    .filter(c => ['ENROLLED', 'INACTIVE', 'INTERMIT'].includes(c.status))
    .map(c => c.courseCode)

  const yearOptions = (courses) => {
    //allow future year selection
    const years = Object.keys(courses)
    const nextYear = (parseInt(defaultYear) + 1).toString()
    if (!years.includes(nextYear)) {
      years.push(nextYear)
    }
    return years
  }

  const campusOptions = (courses, year) => {
    //handle future year selection by using previous years data
    const previousYear = (parseInt(year) - 1).toString()
    let offerings = courses[year]?.offerings 
      || courses[previousYear]?.offerings || courses[0].offerings
    return offerings.map(o => o.location)
  }

  const createTransfer = () => {
    //if the year we want is going to break everything bc it doesnt exist
    if (!transferData.courses[transferData.year]) {
      //save additoinal year value
      transferData.displayYear = transferData.year
      //use previous, or next best
      const previousYear = (parseInt(transferData.year) - 1).toString()
      transferData.year = transferData.courses[previousYear] ?
        previousYear : Object.keys(transferData.courses)[0]
    }
    createNewPlan(null, null, null, transferData)
  }

  const dropdown = (label, key, values) => {
    if (transferData[key] === null) {
      let copy = { ...transferData }
      copy[key]= values[0]
      setTransferData(copy)
    }
    return (
      <div className={c.dropdown}>
        <span>{label}</span>
        <fieldset>
          <select
            value={transferData[key] || values[0]}
            onChange={(e) => {
              let copy = { ...transferData }
              copy[key]= e.target.value
              setTransferData(copy)
            }}>
            {values
              .sort((a, b) => (a > b ? 1 : a < b ? -1 : 0))
              .map((v) => {
                return (
                  <option key={`${v}`} value={v}>
                    {v}
                  </option>
                )
              })}
          </select>
          <div className={c.dropdownDisplay}>
            {transferData[key]}{' '}
            <Icon.ChevronDown />
          </div>
        </fieldset>
      </div>
    )
  }

  const renderPopup = () => {
    return <div className={c.selectEnrolment}>
      {!!selectEnrolment && selectEnrolment !== 'transfer' && (
        <div>
          <div>
            <h2>
              <span>Select enrolment</span>
              <button onClick={() => setSelectEnrolment(false)}>Cancel</button>
            </h2>
            <p>
              <span className={c.name}>
                {currentStudent.personName.firstName}
              </span>{' '}
              has multiple enrolments. Please select one to create a plan from:
            </p>
            <ul className={c.enrolments}>
              {currentStudent.courseEnrolments
                .filter((item) => item.location 
                  && !['UNCONFIRM'].includes(item.status)
                  // && (selectEnrolment === 'enrolled' ? item.status === 'ENROLLED' : true)
                )
                .map((item, i) => (
                  <li
                    key={i}
                    onClick={() => waitAndCreateNewPlan(i)}
                    className={c[item.status?.toLowerCase()]}>
                    <h3>
                      <span>{item.courseCode}</span>
                      <label>{item.status}</label>
                    </h3>
                    <div>{item.course?.title}</div>
                  </li>
                ))}
            </ul>
          </div>
          {selectEnrolment === 'all' && !!currentCourses.length && (
            <>
              <hr />
              <div>
                  <ul className={c.enrolments}>
                    <li 
                      className={c.transfer}
                      onClick={() => setSelectEnrolment('transfer')}
                    >
                      <h3>
                          <span>Create a plan for course transfer</span>
                      </h3>
                    </li>
                  </ul>
              </div>
            </>
          )}
        </div>
      )}
      {selectEnrolment === 'transfer' && (
        <div>
          <h2>
            <span>Create a plan for course transfer</span>
            <button onClick={() => setSelectEnrolment(false)}>Cancel</button>
          </h2>
          <div>
            <div className={c.transferRow}>
              {dropdown(
                'From', 
                'from', 
                currentCourses
              )}
              <span className={c.arrow}><Icon.ArrowLeft/></span>
              <div className={c.dropdown}>
                <span>To</span>
                <CourseSearch
                  value={transferData.courses?.[transferData.year]?.code || ''}
                  selectCourse={(data) => setTransferData({
                    ...transferData,
                    courses: data,
                    year: null,
                    sem: null,
                    campus: null,
                    mode: null
                  })}
                />
              </div>
            </div>
            {transferData.courses && (
              <div>
                {dropdown('Year', 'year', yearOptions(transferData.courses))}
                {transferData.year && (
                  <div>
                    {dropdown('Semester', 'sem', ['S1 - 01 Semester 1', 'S2 - 01 Semester 2'])}
                    {dropdown('Campus', 'campus', campusOptions(transferData.courses, transferData.year))}
                    {dropdown('Study mode', 'mode', ['Full time', 'Part time'])}
                  </div>
                )}
              </div>
            )}
            <div className={c.transferButton}>
              <button 
                className={c.primaryButton}
                disabled={Object.values(transferData).includes(null)}
                onClick={createTransfer}
              >
                Create
              </button>
            </div>
          </div>
          
        </div>
      )}
    </div>
  } 

  // render

  return (
    <div className={c.plans} ref={containerRef}>
      <LoadingScreen loaded={!loading} message={loadingMessage} type={'plan'}>
        {!blockRendering && (
          <div
            className={c.container}
            style={{
              grid: `auto-flow / repeat(${showPlanningPanel ? 1 : 2}, 1fr)`,
            }}>
              <div className={c.createButtons}>
                {/* <button
                  onClick={() => checkEnrolments('enrolled')}
                  className={c.createNewPlan}
                  id='create-new-plan'>
                  <div className={c.icon}>
                    <Icon.Add />
                  </div>
                  <div className={c.text}>
                    Create new plan{enrolledCourses.length === 1 ? 
                      ` for ${enrolledCourses[0].courseCode}`
                    : null}
                  </div>
                </button> */}
                {loadingStudentData ?
                  <div
                    className={c.createNewPlan}
                  >
                    <div className={c.icon}>
                      <LoadingIndicator size={45} color='#204f88' />
                    </div>
                    <div className={c.text}>Loading...</div>
                  </div>
                  :
                  <button
                    onClick={() => checkEnrolments('all')}
                    className={c.createNewPlan}
                    id='create-new-plan-more-options'
                  >
                    <div className={c.icon}>
                      <Icon.Add />
                    </div>
                    <div className={c.text}>Create new plan</div>
                  </button>
                }
              </div>
            {renderPlans}
          </div>
        )}
      </LoadingScreen>

      {/* delete plan */}
      {showDeleteConfirmation && (
        <div className={c.selectEnrolment}>
          <h2>
            <span>Confirm deletion of this plan</span>
          </h2>

          <div className={c.buttonContainer}>
            <button onClick={confirmDelete} className={c.confirmDelete}>
              Confirm
            </button>
            <button onClick={() => setShowDeleteConfirmation(false)}>
              Cancel
            </button>
          </div>
        </div>
      )}

      {/* create plan */}
      {!!selectEnrolment &&
        createPortal(
          renderPopup(),
          document.body
        )}
    </div>
  )
}

export default Plans
